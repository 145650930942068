/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */


export default {
  
  servicesContainer: {
    px: [3, 4]
  }
}
